<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Clients</h4>
        <Dialog
          v-model:visible="dialogOfimport"
          :style="{ width: '450px' }"
          header="IMPORTATION"
          :modal="true"
          class="p-fluid"
        >
        <!-- <input type="file" @change="uploadFile" ref="file"> -->
        <!-- <button @click="submitFile">Import!</button> -->
        <div v-if="!loader">
          <FileUpload mode="basic" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" ref="file" @change="uploadFile" :fileLimit="1" style="width: 100%" label="Importer " chooselabel="Importer " class="mr-2 inline-block" />
            <small style="color:red" v-if="ImagesError"> {{ImagesError}}</small>
          <Button label="télécharger le modèle" icon="pi pi-upload" class="p-button-help" @click="exportCSVexemple()" style="width: 100% ;margin-top:8px"  />
        </div>
          <div v-else>
            <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
          </div>

          <template #footer>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfimport = false"
            />
            <Button
              label="ajouter"
              icon="pi pi-check"
              class="p-button-text"
              @click="importer"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="dialogOfShow"
          :style="{ width: '450px' }"
          header="Détails du client"
          :modal="true"
          class="p-fluid"
        >
          Entreprise : {{ clientToShow.entreprise }}
          <Divider />
          Activité :{{ clientToShow.activite }}
          <Divider />
          Code postal :{{ clientToShow.codePostal }}
          <Divider />
          Adresse :{{ clientToShow.adresse }}
          <Divider />
          Ville :{{ clientToShow.ville }}
          <Divider />
          Email :{{ clientToShow.email }}
          <Divider />
          Téléphone :{{ clientToShow.tele }}
          <Divider />
           Nom et prénom :{{ clientToShow.interlocuteur }}
          <Divider />
          Fonctionalité : {{ clientToShow.nameFeature }}
          <Divider />
          Gsm :{{ clientToShow.gsm }}
          <Divider />
          Téchnicien affécté :

          <label v-for="(product, id) in technicienAffecte" :key="id">
            ( {{ product.nom + " " + product.prenom }} )
          </label>

          <Divider />
          Statut :
          <span v-if="clientToShow.status == 1" style="color: green"
            >Active</span
          ><span v-else style="color: red">désactivé</span>

          <template #footer>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfShow = false"
            />
          </template>
        </Dialog>
        <Toolbar class="mb-4" v-if="Permissions.add">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Ajouter un nouveau client"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="showAddCLient()"
              />
            </div>
          </template>
          <template v-slot:end>
						<!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Importer " chooselabel="Importer " class="mr-2 inline-block" /> -->
            <Button  label="Importer " icon="pi pi-plus" chooselabel="Importer " @click="showImport()" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV()"  />
					</template>
        </Toolbar>
        <DataTable
          :value="clients"
          :paginator="true"
          class="p-datatable-gridlines"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          :loading="loading1"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="['rsocial', 'nom', 'gsm', 'fix', 'address','region']"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Keyword Search"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty> Aucun client trouvé. </template>
          <template #loading>
            Chargement des données clients. Veuillez patienter.
          </template>
          <Column
            field="rsocial"
            header="Raison sociale"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              
              <div v-if="data.rsocial != null">
                {{ data.rsocial }}
              </div>
              <div v-else>
                {{ data.nom }}
              </div>
            </template>
          </Column>
          <Column
            field="nom"
            header=" Nom et prénom "
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.nom }}
            </template>
          </Column>
          <!-- <Column
            field="region"
            header="Région"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.region }}
            </template>
          </Column> -->
          <Column
            field="address"
            header="Adresse"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.address }}
            </template>
          </Column>
          <Column
            field="gsm"
            header="Téléphone"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.gsm }}
            </template>
          </Column>
          <!-- <Column
            field="fix"
            header="FIX"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.fix }}
            </template>
          </Column> -->

          <!-- <Column
            field="etat"
            header="Etat"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="{ data }">
              <Button
                v-if="data.etat == 1"
                @click="changestatuts(data.id, 0)"
                icon="pi pi-check"
                class="p-button-raised p-button-success"
                disabled
              />
              <Button
                v-else
                @click="changestatuts(data.id, 1)"
                icon="pi pi-times"
                class="p-button-raised p-button-danger"
                disabled
              />
            </template>
          </Column> -->
          <Column header="Actions" style="min-width: 12rem">
            <template #body="data">
              <span class="p-buttonset">
                <!-- <Button
                  @click="showclientById(data.data.id)"
                  style="margin-right: 6px"
                  icon="pi pi-eye"
                  class="p-button-raised p-button-rounded p-button-success"
                /> -->
                <Button
                  v-if="Permissions.update"
                  style="margin-right: 6px"
                  icon="pi pi-user-edit"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="updateClient(data.data)"
                />
                <Button
                  v-if="Permissions.delete"
                  icon="pi pi-trash"
                  class="p-button-raised p-button-rounded p-button-danger"
                  @click="deleteclient(data.data.id)"
                />
              </span>
            </template>
          </Column>
        </DataTable>
        <Dialog
          v-model:visible="dialogOfAdd"
          :style="{ width: '900px' }"
          header="Ajouter un client"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-12">
                <input type="radio" name="cltType" value="particulier" v-model="cltType" />
          <label for="particulier">Particulier</label>
          <input type="radio" name="cltType" value="entreprise" v-model="cltType" />
          <label for="entreprise">Entreprise</label>
              </div>
              <div class="field col-12 md:col-6" v-if="cltType!='particulier'">
                <label for="firstname2">Raison sociale </label>

                <InputText
                  v-model="clientToAdd.rsocial"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['rsocial'] }"
                />
                <small class="p-error" v-if="errorsAdd['rsocial']">
                  {{ errorsAdd["rsocial"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6" v-if="cltType!='particulier'">
                <label for="firstname2">ICE </label>
                <InputMask 
                v-model="clientToAdd.ice"
                mask="999999999999999"
                :class="{ 'is-invalid': errorsAdd['ice'] }"
              />
                <!-- <InputText
                  v-model="clientToAdd.ice"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['ice'] }"
                /> -->
                <small class="p-error" v-if="errorsAdd['ice']">
                  {{ errorsAdd["ice"] }}
                </small>
              </div>
              
              <!-- <div class="field col-12 md:col-6">
                <label for="city">Plafond </label>
                <InputNumber
                  v-model="clientToAdd.plafond"
                  mode="currency"
                  currency="MAD"
                  :class="{ 'is-invalid': errorsAdd['plafond'] }"
                />
                <small class="p-error" v-if="errorsAdd['plafond']">
                  {{ errorsAdd["plafond"] }}
                </small>
              </div> -->
              <div class="field col-12 md:col-6">
                <label for="lastname2"> Nom et prénom  </label>
                <InputText
                  v-model="clientToAdd.nom"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['nom'] }"
                />
                <small class="p-error" v-if="errorsAdd['nom']">
                  {{ errorsAdd["nom"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="city">Téléphone  </label>
                <InputMask :class="{ 'is-invalid': errorsAdd['gsm'] }" id="phone" v-model="clientToAdd.gsm" mask="+212 999999999"></InputMask>
                <!-- <InputText
                  v-model="clientToAdd.gsm"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['gsm'] }"
                /> -->
                <small class="p-error" v-if="errorsAdd['gsm']">
                  {{ errorsAdd["gsm"] }}
                </small>
              </div>
              <!-- <div class="field col-12 md:col-6">
                <label for="city">Fix </label>
                <InputText
                  v-model="clientToAdd.fix"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['fix'] }"
                />
              </div> -->
              <!-- <div class="field col-12 md:col-6">
                <label for="lastname2">GPS </label>
                <InputText
                  v-model="clientToAdd.gps"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['gps'] }"
                />
                <small class="p-error" v-if="errorsAdd['gps']">
                  {{ errorsAdd["gps"] }}
                </small>
              </div> -->
              <!-- <div class="field col-12 md:col-6">
                <label for="lastname2">Région </label>
                <InputText
                  v-model="clientToAdd.region"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['region'] }"
                />
                <small class="p-error" v-if="errorsAdd['region']">
                  {{ errorsAdd["region"] }}
                </small>
              </div> -->
              <div class="field col-12 md:col-6">
                <label for="address">Adresse </label>
                <Textarea
                  v-model="clientToAdd.address"
                  id="address"
                  rows="4"
                  :class="{ 'is-invalid': errorsAdd['address'] }"
                />
                <small class="p-error" v-if="errorsAdd['address']">
                  {{ errorsAdd["address"] }}
                </small>
              </div>

              <!-- <div class="field col-12">
                <label for="lastname2" style="text-align: center">Statut</label>
                <InputSwitch
                  v-model="clientToAddstatusswitch"
                  @change="onswitchAdd"
                />
              </div> -->
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfAdd = false"
            />
            <Button
              label="ajouter"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormAdd"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="dialogOfEdit"
          :style="{ width: '900px' }"
          header="Modifier"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-12">
                <input type="radio" name="cltType" value="particulier" v-model="cltType" />
          <label for="particulier">Particulier</label>
          <input type="radio" name="cltType" value="entreprise" v-model="cltType" />
          <label for="entreprise">Entreprise</label>
              </div>
              <div class="field col-12 md:col-6" v-if="cltType!='particulier'">
                <label for="firstname2">Raison sociale </label>

                <InputText
                  v-model="clientToAdd.rsocial"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['rsocial'] }"
                />
                <small class="p-error" v-if="errorsAdd['rsocial']">
                  {{ errorsAdd["rsocial"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6" v-if="cltType!='particulier'">
                <label for="firstname2">ICE </label>
                <InputMask 
                v-model="clientToAdd.ice"
                mask="999999999999999"
                :class="{ 'is-invalid': errorsAdd['ice'] }"
              />
                <!-- <InputText
                  v-model="clientToAdd.ice"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['ice'] }"
                /> -->
                <small class="p-error" v-if="errorsAdd['ice']">
                  {{ errorsAdd["ice"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6" >
                <label for="lastname2"> Nom et prénom  </label>
                <InputText
                  v-model="clientToAdd.nom"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['nom'] }"
                />
                <small class="p-error" v-if="errorsAdd['nom']">
                  {{ errorsAdd["nom"] }}
                </small>
              </div>
              <!-- <div class="field col-12 md:col-6">
                <label for="city">Plafond </label>
                <InputNumber
                  v-model="clientToAdd.plafond"
                  mode="currency"
                  currency="MAD"
                  :class="{ 'is-invalid': errorsAdd['plafond'] }"
                />
                <small class="p-error" v-if="errorsAdd['plafond']">
                  {{ errorsAdd["plafond"] }}
                </small>
              </div> -->
              <div class="field col-12 md:col-6">
                <label for="city">Téléphone </label>
                <InputMask :class="{ 'is-invalid': errorsAdd['gsm'] }" id="phone" v-model="clientToAdd.gsm" mask="+212 999999999"></InputMask>
                <!-- <InputText
                  v-model="clientToAdd.gsm"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['gsm'] }"
                /> -->
                <small class="p-error" v-if="errorsAdd['gsm']">
                  {{ errorsAdd["gsm"] }}
                </small>
              </div>
              <!-- <div class="field col-12 md:col-6">
                <label for="city">Fix </label>
                <InputText
                  v-model="clientToAdd.fix"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['fix'] }"
                />
              </div> -->
              <!-- <div class="field col-12 md:col-6">
                <label for="lastname2">GPS </label>
                <InputText
                  v-model="clientToAdd.gps"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['gps'] }"
                />
                <small class="p-error" v-if="errorsAdd['gps']">
                  {{ errorsAdd["gps"] }}
                </small>
              </div> -->
              <!-- <div class="field col-12 md:col-6">
                <label for="lastname2">Région </label>
                <InputText
                  v-model="clientToAdd.region"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['region'] }"
                />
                <small class="p-error" v-if="errorsAdd['region']">
                  {{ errorsAdd["region"] }}
                </small>
              </div> -->
              <div class="field col-12 md:col-6">
                <label for="address">Adresse </label>
                <Textarea
                  v-model="clientToAdd.address"
                  id="address"
                  rows="4"
                  :class="{ 'is-invalid': errorsAdd['address'] }"
                />
                <small class="p-error" v-if="errorsAdd['address']">
                  {{ errorsAdd["address"] }}
                </small>
              </div>

              <!-- <div class="field col-12">
                <label for="lastname2" style="text-align: center">Statut</label>
                <InputSwitch
                  v-model="clientToAddstatusswitch"
                  @change="onswitchAdd"
                />
              </div> -->
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfEdit = false"
            />
            <Button
              label="Modifier"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormAdd"
            />
          </template>
        </Dialog>
        <ConfirmDialog></ConfirmDialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default {
  data() {
    return {
      Permissions: { update: false, delete: false, add: false, show: false },
      clients: [],
      filters1: null,
      loading1: true,
      Images:null,
      dialogOfShow: false,
      dialogOfimport: false,
      clientToShow: {},
      ImagesError:null,
      dialogOfEdit: false,
      updatesubmitted: false,
      url:null,
      dialogOfAdd: false,
      loader: false,
      clientToAdd: {
        id: null,
        rsocial: null,
        nom: null,
        fix: null,
        gsm: null,
        address: null,
        gps: null,
        etat: 1,
        plafond: null,
        region: null,
        ice: null,
      },
      cltType: 'entreprise',
      clientToAddstatusswitch: true,
      errorsAdd: [],
      multiselectValueAdd: [],
      feature_id: null,
    };
  },

  mounted() {
    this.Permissions.update =
      localStorage.permissionNames.includes("Modifier client");
    this.Permissions.delete =
      localStorage.permissionNames.includes("Supprimer client");
    this.Permissions.add =
      localStorage.permissionNames.includes("Ajouter client");
    this.Permissions.show =
      localStorage.permissionNames.includes("Liste client");
    if (this.Permissions.show) {
      this.showAllclients();
    } else {
      this.$router.push("/");
    }
  },

  created() {
    // if (localStorage.token == "" || localStorage.token == null) {
    //   this.$router.push("/login");
    // }
    this.initFilters1();
    this.url = this.$Gurl;
  },
  methods: {
    uploadFile() {
        this.Images = this.$refs.file.files[0];
        console.log(this.Images);
      },
    importer(){
      this.ImagesError=null;
      if (this.Images==null) {
        this.ImagesError='Merci de sélectionner un fichier excel';
      }else{
        console.log("excel",this.Images);
        this.loader =true;
      const formData = new FormData();
      formData.append("fichierExcel", this.Images);
      axios
        .post("importClient", formData)
        .then((response) => {
          console.log(response);
          this.loader =false;
          this.dialogOfimport = false;
          this.Images=null;
          this.showAllclients();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
      }
     
    },
    showImport(){
        this.dialogOfimport=true;
    },
    exportCSVexemple(){
			window.open(this.url+'/fichierExcel/exempleClient.xlsx', '_blank');
    },
    exportCSV() {
			window.open(this.url+'/api/exportClient', '_blank');
		},
    deleteclient(id) {
      this.$swal({
        icon: "warning",
        title: "Vous êtes sûr de vouloir continuer ?",
        showDenyButton: true,
        confirmButtonText: "supprimer",
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post("supprimerClient/" + id)
            .then(() => {
              var index = this.clients
                .map((x) => {
                  return x.id;
                })
                .indexOf(id);
              this.clients.splice(index, 1);
              console.log(this.clients);
              this.$swal({
                icon: "success",
                title: "Supprimé",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    updateclient() {
      axios
        .post("modifierClient/" + this.clientToAdd.id, this.clientToAdd)
        .then((response) => {
          this.showAllclients();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.dialogOfEdit = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },

    initformadd() {
      this.clientToAdd.rsocial = null;
      this.clientToAdd.nom = null;
      this.clientToAdd.fix = null;
      this.clientToAdd.gsm = null;
      this.clientToAdd.address = null;
      this.clientToAdd.gps = null;
      this.clientToAdd.plafond = null;
      this.clientToAdd.region=null;
      this.clientToAdd.ice=null;
      this.clientToAdd.etat = 1;
    },
    showAllclients() {
      axios
        .get("getClients")
        .then((response) => {
          this.clients = response.data.clients;
          console.log(response);
          this.loading1 = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    addClient() {
      axios
        .post("addClient", this.clientToAdd)
        .then((response) => {
          console.log(response);
          this.showAllclients();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.dialogOfAdd = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    showAddCLient() {
      this.initformadd();
      this.errorsAdd = [];
      this.dialogOfAdd = true;
    },
    checkFormAdd() {
      let checked = true;
      this.errorsAdd = [];
// if (this.clientToAdd.region == null || this.clientToAdd.region == "") {
//         this.errorsAdd["region"] = "Région est obligatoire.";
//         checked = false;
//       }
      // if (this.clientToAdd.rsocial == null || this.clientToAdd.rsocial == "") {
      //   this.errorsAdd["rsocial"] = "Raison sociale est obligatoire.";
      //   checked = false;
      // }
      // if (this.clientToAdd.plafond == "") {
      //   this.clientToAdd.plafond = null;
      // }
      
      if (this.cltType != "particulier") {
          if (this.clientToAdd.rsocial == null || this.clientToAdd.rsocial == "") {
            this.errorsAdd["rsocial"] = "Raison sociale est obligatoire.";
            checked = false;
          }
          if (this.clientToAdd.ice == null || this.clientToAdd.ice == "") {
            this.errorsAdd["ice"] = "ICE est obligatoire.";
            checked = false;
          }
        }
      if (this.clientToAdd.nom == null || this.clientToAdd.nom == "") {
        this.errorsAdd["nom"] = " Nom et prénom  est obligatoire.";
        checked = false;
      }
      if (this.clientToAdd.gsm == null || this.clientToAdd.gsm == "") {
        this.errorsAdd["gsm"] = "Téléphone est obligatoire.";
        checked = false;
      }
      // if (this.clientToAdd.fix == null || this.clientToAdd.fix == "") {
      //   this.errorsAdd["fix"] = "FIX est obligatoire.";
      //   checked = false;
      // }
      if (this.clientToAdd.address == null || this.clientToAdd.address == "") {
        this.errorsAdd["address"] = "Adresse est obligatoire.";
        checked = false;
      }
      // if (this.clientToAdd.gps == null || this.clientToAdd.gps == "") {
      //   this.errorsAdd["gps"] = "gps est obligatoire.";
      //   checked = false;
      // }

      if (checked) {
        if (this.dialogOfAdd) {
          this.addClient();
        } else {
          this.updateclient();
        }
      }
    },

    updateClient(data) {
      this.initformadd();
      if (data.ice==null) {
        this.cltType= 'particulier';
      }else{
        this.cltType= 'entreprise';
      }
      this.clientToAdd.id = data.id;
      this.clientToAdd.rsocial = data.rsocial;
      this.clientToAdd.nom = data.nom;
      this.clientToAdd.fix = data.fix;
      this.clientToAdd.gsm = data.gsm;
      this.clientToAdd.address = data.address;
      this.clientToAdd.gps = data.gps;
      this.clientToAdd.etat = data.etat;
      this.clientToAdd.plafond = data.plafond;
      this.clientToAdd.region=data.region;
      this.clientToAdd.ice=data.ice;
      this.dialogOfEdit = true;
    },

    onswitchAdd() {
      if (this.clientToAddstatusswitch == true) {
        this.clientToAdd.status = 1;
      } else {
        this.clientToAdd.status = 0;
      }
    },
    // showclientById(id) {
    //   axios
    //     .get("getClientByid/" + id)
    //     .then((response) => {
    //       this.clientToShow = response.data.clients;
    //       this.technicienAffecte = response.data.technicienAffecte;

    //       console.log("this.clientToShow", response);
    //       console.log("this.technicienAffecte", this.technicienAffecte);
    //       this.dialogOfShow = true;
    //     })
    //     .catch((err) => {
    //       if (err.response.data.message) {
    //         this.$swal("Erreur ", err.response.data.message);
    //       } else {
    //         this.$swal("Erreur ", err.message);
    //       }
    //     });
    // },
    // changestatuts(id, status) {
    //   this.$swal({
    //     title: "Voulez-vous vraiment changer le statuts?",
    //     showDenyButton: true,
    //     confirmButtonText: "Changer",
    //     denyButtonText: `Annuler`,
    //   }).then((result) => {
    //     /* Read more about isConfirmed, isDenied below */
    //     if (result.isConfirmed) {
    //       axios
    //         .post("statuclient/" + id, { status: status })
    //         .then(() => {
    //           const index = this.clients.findIndex((object) => {
    //             return object.id === id;
    //           });
    //           this.clients[index].status = status;
    //           this.$swal({
    //             icon: "success",
    //             title: "Le statut est bien actualisé",
    //             showConfirmButton: false,
    //             timer: 1500,
    //           });
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //           if (err.message) {
    //             this.$swal("Erreur ", err.message);
    //           } else {
    //             this.$swal("Erreur ", err.message);
    //           }
    //         });
    //     }
    //   });
    // },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },

    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    formatDate(value) {
      return value.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    calculateCustomerTotal(name) {
      let total = 0;
      if (this.customer3) {
        for (let customer of this.customer3) {
          if (customer.representative.name === name) {
            total++;
          }
        }
      }

      return total;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/demo/badges.scss";
@import "../assets/demo/badges.scss";
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}
.is-invalid {
  border-color: #e24c4c;
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}
</style>